import { CircularProgress } from '@mui/material';

import { colors } from '@/constants/colors';

interface SimpleLoaderProps {
  color?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}
const SimpleLoader = ({
  color,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
}: SimpleLoaderProps) => (
  <CircularProgress
    data-testid="circular-progress"
    size={25}
    sx={{
      color: color || colors.white,
      marginTop,
      marginLeft,
      marginBottom,
      marginRight,
    }}
  />
);

export default SimpleLoader;
