import { Box, Container } from '@mui/material';
import styled from 'styled-components';

import { colors } from '@/constants/colors';

export const MainContainer = styled(Container)`
  max-width: 665px !important;
  padding: 0 18px !important;
  margin: 0 auto 45px !important;
  @media screen and (max-width: 768px) {
    min-width: 320px !important;
    max-width: 480px !important;
  }
`;

interface FormContainerProps {
  iswide?: boolean;
  width?: string;
  nopadding?: string;
}

// @ts-ignore
// width overrides iswide prop
export const FormContainer = styled(MainContainer<FormContainerProps>)`
  margin-top: 0 !important;
  ${(props: any) => props.nopadding === 'true' && 'padding: 0 !important;'}
  max-width: ${(props) =>
    props.iswide === 'true' ? '910px' : '670px'} !important;
  max-width: ${(props) => (props.width ? `${props.width} !important` : '')};
`;

interface InitialsProps {
  rounded?: string;
}

export const Initials = styled(Box)<InitialsProps>`
  border: 1px solid ${colors.green60};
  border-radius: ${(props) => (props.rounded ? '50%' : '6px')};
  font-weight: 500;
  font-size: 16px;
  color: ${colors.green};
  background-color: ${colors.lightGreen};
  width: ${(props) => (props.rounded ? '50px' : '89px')};
  min-width: ${(props) => (props.rounded ? '50px' : '89px')};
  height: ${(props) => (props.rounded ? '50px' : '89px')};
  overflow: hidden;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
`;

const IconStyle = styled.div`
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  transition: 0.4s ease;
  background-color: ${colors.lightGreen};
`;

export const IconWrapper = styled(IconStyle)`
  svg {
    position: relative;
    left: 5px;
    bottom: 1px;
  }
  background-color: ${colors.purpleFig};
`;

export const IconWrapperEmptyRadio = styled(IconStyle)`
  background-color: ${colors.white};
  border: 2px solid ${colors.slate60};
`;

export const IconWrapperEmptyCheckbox = styled(IconWrapperEmptyRadio)`
  border-radius: 0;
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
